




































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Reports, User, Props, Global, Groups, MapSettings } from '@/store';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import MapFilter from './components/filter/MapFilter.vue';
import PropEditor from '@/components/PropEditor.vue';
import ProjectInformation from '../../components/ProjectInformation.vue';
import TondoTabs from './components/MapTabs/devices/TondoInfoTabs.vue';
import AssetTabs from './components/MapTabs/devices/AssetInfoTabs.vue';
import RadarTabs from './components/MapTabs/devices/RadarInfoTabs.vue';
import VideoTabs from './components/MapTabs/devices/VideoInfo.vue';
import SensorTabs from './components/MapTabs/devices/SensorInfoTabs.vue';
import WaterMeterTabs from './components/MapTabs/devices/WaterMeterInfoTabs.vue';
import FloodSensorInfoTabs from './components/MapTabs/devices/FloodSensorInfoTabs.vue';
import WeatherStationInfoTabs from './components/MapTabs/devices/WeatherStationInfoTabs.vue';
import GoogleMap from './components/map/GMap.vue';
import cloneDeep from 'lodash/cloneDeep';
import ManageSelectedDevices from '@/components/ManageSelectedDevices.vue';
import mapIconsConfig from '@/pages/mapView/components/map/map.config';

@Component({
  components: {
    PropEditor,
    ProjectInformation,
    MapFilter,
    TondoTabs,
    AssetTabs,
    RadarTabs,
    VideoTabs,
    SensorTabs,
    WaterMeterTabs,
    FloodSensorInfoTabs,
    WeatherStationInfoTabs,
    GoogleMap,
    ManageSelectedDevices
  }
})
export default class MapView extends Vue {
  @Global.State('isMobile') isMobile;
  @Global.State('editFilterMode') editFilterMode;
  @Global.State('lang') lang;
  @Global.State('sensorPage') sensorPage;
  @Global.State('waterMeterPage') waterMeterPage;
  @Global.State('timezone') projectTimezone;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Reports.State('devicesCount') devicesCount;
  @Reports.State('devicesChangedFromMqtt') devicesChangedFromMqtt;
  @Reports.State('reportsList') devices;
  @Reports.Mutation('removeDeviceChangedFromMqtt') removeDeviceChangedFromMqtt;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Reports.Getter('commissionedDevices') commissionedDevices;
  @Reports.Getter('commissionedCabinets_map') commissionedCabinets_map;
  @Reports.Getter('cabinets_fixtures') cabinets_fixtures;
  @Reports.Getter('commissionedFixtures') commissionedFixtures;
  @MapSettings.State('isLoading') isLoading;
  @Props.State('list') projectProperties;
  @User.State('project') project;
  @User.State('properties') userProperties;
  @User.State('username') username;
  @User.State('isAuth') isAuth;
  @User.State('role') role;
  @User.State('default_map') default_map;
  @Groups.State('list') groups;

  @Prop() filterDevicesList;
  @Prop() displayOnly;
  @Prop() unassigned;
  @Prop() dragOnly;

  OVERVIEW_TAB = 'tab-overview';
  isDevicesLoading = false;
  searchClicked = false;
  filterDialog = false;
  filter = {
    groups: ['all'],
    classes: null,
    type: null,
    zoom: null,
    status: null,
    phase: null,
    circuit: null,
    name: 'default',
    map: {
      bounds: null,
      center: null,
      zoom: null
    },
    assetType: null
  };

  regions = [];
  filterDevices = [];
  mapDevices = [];
  containers = [];
  streamDialog = false;
  embedContent = null;
  REGION_COLUMN = 'Region';
  isTondo = false;
  isHub = false;
  isAsset = false;
  isRadar = false;
  isVideo = false;
  isContainer = false;
  isMotionSensor = false;
  isWaterMeter = false;
  isFloodSensor = false;
  isBarrier = false;
  isWeatherStation = false;
  container_id = null;
  devicesSelect = false;
  groupDevices = new Map();
  editedDevices = null;
  language = '';
  devices_list_data = new Map();
  fullMap = false;
  current_device = null;
  loadingDevices = false;
  advancedMap = false;
  tab = 'tab-overview';

  created(){
    if (this.default_map && this.default_map.toLowerCase() === 'advanced'){
      this.advancedMap = true;
    }
  }

  mounted() {
    this.language = this.lang;
    if (this.devicesCount !== this.devices.length) {
      this.loadingDevices = true;
    }
    this.$root.$refs.MapView = this;
    if (this.$route.path === '/') this.setPageTitle('Full View');

    this.setMap();
    const timeout = this.project.id === 'allProjects' ? 25000 : 15000;
    setTimeout(() => {
      this.loadingDevices = false;
    }, timeout);
  }

  setAdvancedMap(){
    this.advancedMap = !this.advancedMap;
  }

  setLoadingDevices(is_loading){
    this.loadingDevices = is_loading;
  }

  @Watch('devices.length')
  setMap() {
    this.groupDevices = new Map();
    this.fetchData();
    this.generateDevicesData();
    if (this.devicesCount === this.devices.length) {
      this.loadingDevices = false;
    }
  }

  get defaultDisplay(){
    return (this.$route.path === '/' || this.$route.path.includes('custom') || this.$route.path.includes('/filter/create')) && !this.fullMap;
  }

  clearPolygonData(){
    this.$refs.map['clearDeviceSelector']();
  }

  generateDevicesData(){
    this.commissionedDevices.forEach((device) => 
      this.devices_list_data.set(device.id, {
        id: device.id, 
        class_name: device.class_name, 
        icon: this.getDeviceIcon(device), 
        text: this.getDeviceInformation(device),
        device
      })
    );
  }

  getDeviceInformation(device) {
    const metaDevice = this.commissioned_map.get(device.id)['meta.device'];
    const cabinet_id = metaDevice.cabinet_id;
    const circuit_number = metaDevice.circuit_number;
    const pole_number = metaDevice.pole_number;

    let list_item_title = `${device.name}`;

    if (cabinet_id)
      list_item_title = `${list_item_title}, ${this.$t('Cabinet ID' )}: ${cabinet_id}`;
    if (circuit_number)
      list_item_title = `${list_item_title}, ${this.$t('Circuit Number')}: ${circuit_number}`;
    if (pole_number)
      list_item_title = `${list_item_title}, ${this.$t('Pole Number')}: ${pole_number}`;

    return list_item_title;
  }

  getDeviceIcon(device){
    if (Utils.hasTondoClass(device.class_name)) {
      return mapIconsConfig.icons[`${Utils.getTondoIconType(device)}Off`];
    }
    if (Utils.hasCabinetClass(device.class_name)){
      return mapIconsConfig.icons.regularCabinetOff;
    }
    if (Utils.hasVirtualCabinetClass(device.class_name, device['meta.device'])){
      return mapIconsConfig.icons.virtualCabinet;
    }
    if (Utils.hasMotionSensorClass(device.class_name)){
      return mapIconsConfig.icons.sensorOk;
    }
    if (Utils.hasWaterMeterClass(device.class_name)){
      return mapIconsConfig.icons.waterMeter;
    }
    if (Utils.hasCameraClass(device.class_name)){
      return mapIconsConfig.icons.cameraOk;
    }
    if (Utils.hasFloodSensorClass(device.class_name, device['meta.category.category'])) {
      return mapIconsConfig.icons.floodSensor;
    }
    if (Utils.hasBarrierClass(device.class_name, device['meta.category.category'])){
      return mapIconsConfig.icons.closedBarrier;
    }
    return mapIconsConfig.icons.defaultMarker;
  }

  @Watch('filterDevicesList')
  setDeviceFilter() {
    this.fetchData();
  }

  fetchData() {
    if (this.isAuth && this.project) {
      this.initMapRegion();
      this.filteredData();
    }
  }

  get info() {
    return this.filter.name && this.filter.name !== 'default'
      ? this.filter.name
      : this.$t('Full View');
  }

  clear() {
    [...this.groupDevices.values()].forEach((device) => this.$refs.map['updateFeatureIcon'](device));
    this.groupDevices = new Map();
  }

  setIsFullMap(is_full_map){
    this.fullMap = is_full_map;
  }

  updateSelectedDevices(devices, state = null, from = '') {
    this.groupDevices = cloneDeep(this.groupDevices);
    const tempDevices = devices.filter((device) => Utils.hasTondoClass(device.class_name));
    if (state === true) {
      tempDevices.forEach((device) => {
        if (!this.groupDevices.has(device.id)) {
          this.groupDevices.set(device.id, device);
          this.$refs.map['updateFeatureIcon'](device);
        }
      });
      if (from === 'filter') this.$nextTick(() => this.$refs.map['setMapBounds']());
    } else if (state === false) {
      tempDevices.forEach((device) => {
        if (this.groupDevices.has(device.id)) {
          this.groupDevices.delete(device.id);
          this.$refs.map['updateFeatureIcon'](device);
        }
      });
    } else {
      tempDevices.forEach((device) => {
        this.groupDevices.has(device.id)
          ? this.groupDevices.delete(device.id)
          : this.groupDevices.set(device.id, device);
        this.$refs.map['updateFeatureIcon'](device);
      });
    }
  }

  get is_device_info_open(){
    return this.isTondo;
  }

  setSearchClicked(clicked) {
    this.searchClicked = clicked;
    if (clicked) {
      this.$refs.map['closeInfoWindow']();
      this.clearPolygonData();
    }
  }

  setContainersDevices(containers) {
    this.containers = cloneDeep(containers);
  }

  setIsContainer(is_container) {
    this.isContainer = is_container;
    this.container_id = null;
  }

  async filteredData() {
    if (this.project) {
      await this.setFilterDevices();
      this.setMapDevices();
    }
  }

  async setFilterDevices(){
    this.filterDevices = [];
    if (this.$route.path.includes('custom') || this.$route.path.includes('/filter/create')){
      this.filterDevices = await this.getDevicesByFilter();
    }else if (!this.filterDevicesList){
      this.filterDevices = this.commissionedDevices;
    }
  }

  setMapDevices(){
    if (this.filterDevicesList) {
      const map_devices = [];
      this.filterDevicesList.forEach((device_id) => {
        const device = this.commissioned_map.get(device_id);
        if (device) {
          map_devices.push(device);
        }
      });
      this.mapDevices = map_devices;
    }else if (this.$route.params.cid) {
      this.mapDevices = this.getCabinetDevices(this.$route.params.cid);
    }else {
      this.mapDevices = this.filterDevices;
    }
  }

  getCabinetDevices(device_id){
    const cabinet = this.commissionedCabinets_map.get(device_id);
    let cabinet_devices = [];

    if (cabinet) {
      cabinet_devices = this.cabinets_fixtures.get(cabinet.id);
    }

    return cabinet ? cabinet_devices.concat([cabinet]) : [];
  }

  initMapRegion() {
    this.regions = [];
    let regionsProperty = PropUtils.getProperty(this.projectProperties, 'dashboard.region_users');
    regionsProperty = regionsProperty ? regionsProperty.value : {};
    const users_by_region = Object.entries(regionsProperty);
    if (users_by_region.length) {
      users_by_region.forEach(([region, users]) => {
        if (users['includes'](this.username)) {
          this.regions.push(region);
        }
      });
    }
  }

  // filterDevicesByRegions() {
  //   const devicesRegions = [];
  //   if (!this.regions.length) {
  //     return this.filterDevices;
  //   }
  //   this.filterDevices.forEach((device) => {
  //     if (this.metaDeviceByDevice_map.has(device.id)) {
  //       const deviceRegion = this.metaDeviceByDevice_map.get(device.id)[
  //         this.REGION_COLUMN
  //       ];
  //       if (deviceRegion && this.regions.includes(deviceRegion))
  //         devicesRegions.push(device);
  //     }
  //   });
  //   return devicesRegions;
  // }

  changeDevicesData() {
    [...this.groupDevices.keys()].forEach((device_id) => {
      this.groupDevices.set(device_id, this.commissioned_map.get(device_id));
    });

    const editedDevices = JSON.parse(JSON.stringify(this.mapDevices));
    for (let i = 0; i < editedDevices.length; i++) {
      if (this.groupDevices.has(editedDevices[i].id)) {
        editedDevices[i] = this.groupDevices.get(editedDevices[i].id);
      }
    }
    this.editedDevices = editedDevices;
  }

  changeDevicesList(devices, tab_name) {
    this.tab = tab_name;
    this.mapDevices = [...devices];
    this.editedDevices = null;
    this.$nextTick(() => {
      if (this.current_device && this.$refs.map) this.$refs.map['setBounds']([this.current_device]);
    });
  }

  async getDevicesByFilter() {
    if (this.filter.groups) {
      const devicesInFilterGroups = this.filter.groups.includes('all')
        ? this.commissionedDevices
        : await this.getGroupsDevices();
      
      const filteredDevices = this.project.id === 'allProjects'
        ? this.commissionedDevices
        : [...devicesInFilterGroups].filter((device) => this.checkBelongsToFilter(device));
      if (this.$route.params.cid) {
        return filteredDevices.filter(
          (device) =>
            (Utils.hasTondoClass(device.class_name) && device['meta.device']).cabinet_id === this.$route.params.cid ||
            ((Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device'])) &&
            device['meta.device'].cabinet_id === this.$route.params.cid)
        );
      } else {
        return filteredDevices;
      }
    }
  }

  checkBelongsToFilter(device) {
    let hasStatus = true;
    let hasType = true;
    let hasAssetType = true;
    let hasCircuit = true;
    let hasPhase = true;
    hasType = this.filter.type
      ? this.filter.type.includes(device['meta.category.category'])
      : true;
    const hasFixture =
      this.filter.type &&
      this.filter.type.includes('FIXTURE') &&
      device['meta.category.category'] === 'FIXTURE' &&
      this.filter.groups.includes('all');
    if (hasFixture) {
      const metaDevice = device['meta.device'];
      hasCircuit = metaDevice.circuit_number
        ? this.filter.circuit &&
          this.filter.circuit.includes(metaDevice.circuit_number)
        : true;
      hasPhase = metaDevice.phase
        ? this.filter.phase && this.filter.phase.includes(metaDevice.phase)
        : true;
    }

    if (this.filter.assetType && device['meta.type.type']) {
      hasAssetType = this.filter['assetType'].includes(
        device['meta.type.type']
      );
    }
    if (this.filter.status) {
      const connection_status =
        device['sys___active'] === 0 ? 'disconnected' : 'connected';
      hasStatus = this.filter['status'].includes(connection_status);
    }

    return hasCircuit && hasPhase && hasStatus && hasType && hasAssetType;
  }

  async getGroupsDevices() {
    let devicesInFilterGroups = new Set();
    let filter_devices = [];
    try {
      const groups_data = await Promise.all(
        this.filter.groups.map((group_id) => API.get(Types.PROJECTS, `${this.project.id}/groups/${group_id}`, {}, {}, 'v4'))
      );
      groups_data.forEach((group) => devicesInFilterGroups = new Set([...devicesInFilterGroups, ...group['devices']]));
      filter_devices = this.commissionedDevices.filter((device) => devicesInFilterGroups.has(device.id));
    } catch (e) {
      filter_devices = [];
      console.log('There was problem to fetch filter devices from all groups');
    }

    return filter_devices;
  }

  clearDevice() {
    if (this.$refs.map) this.$refs.map['closeInfoWindow']();
    if (this.$refs.map) this.$refs.map['clearPolylines']();
    this.clearDeviceData();
  }

  clearDeviceData(){
    this.current_device = null;
    this.closeTabs();
    if ((this.$route.path.includes('custom') && this.editFilterMode) || this.$route.path.includes('/filter/create')) this.filterDialog = true;
  }

  closeTabs(){
    if (this.isTondo) this.isTondo = false;
    else if (this.isHub) this.isHub = false;
    else if (this.isAsset) this.isAsset = false;
    else if (this.isRadar) this.isRadar = false;
    else if (this.isVideo) this.isVideo = false;
    else if (this.isContainer) this.isContainer = false;
    else if (this.isMotionSensor) this.isMotionSensor = false;
    else if (this.isWaterMeter) this.isWaterMeter = false;
    else if (this.isFloodSensor) this.isFloodSensor = false;
    else if (this.isBarrier) this.isBarrier = false;
    else if (this.isWeatherStation) this.isWeatherStation = false;
  }

  showTabs() {
    let category = this.current_device && this.current_device['meta.category.category'];
    if (category) {
      category = category.toLocaleLowerCase();
      this.isTondo = category === 'fixture';
      this.isHub = category === 'hub' || category === 'cabinet';
      this.isAsset = category === 'asset';
      this.isRadar = category === 'radar';
      this.isVideo = category === 'video';
      this.isMotionSensor = category === 'motion sensor';
      this.isWaterMeter = category === 'water meter';
      this.isFloodSensor = category === 'flood sensor';
      this.isBarrier = category === 'barrier';
      this.isWeatherStation = category === 'weather station';
    }
  }

  showDeviceInformation(device) {
    if (this.displayOnly) return;

    // if (this.isContainer && device.container_id) {
    //   this.container_id = device.container_id;
    //   this.device = this.containers[this.container_id][0];
    // } else {
    //   this.device = device;
    // }
    this.current_device = device;
    this.showTabs();
    this.filterDialog = false;
    if (this.searchClicked) (this.$root.$refs.ProjectInformation as ProjectInformation).mapDevicesChanged(this.OVERVIEW_TAB);
    
    if (device['meta.stream'] && device['meta.stream'] !== null) {
      const stream = PropUtils.parseProperty('meta.stream', device);
      if (stream.embed_url) {
        this.embedContent = this.contentToDisplay(stream);
        this.streamDialog = true;
      }
    }
  }

  changeVirtualLocation(new_location){
    this.$emit('changeVirtualLocation', new_location);
  }

  contentToDisplay(stream) {
    if (stream.type === 'video') {
      return `<iframe id='fp_embed_player' src='https://${stream.server_url}:8888/embed_player?urlServer=wss://${stream.server_url}:8443&streamName=${stream.embed_url}&autoplay=true&mediaProviders=WebRTC,Flash,MSE,WSPlayer' 
      marginwidth='0' marginheight='0' frameborder='0' scrolling='no'></iframe>`;
    } else if (stream.type === 'image') {
      return `<img width="100%" style="-webkit-user-select: none;" src="${stream.embed_url}">`;
    } else {
      return `<iframe width="100%" height="600px" src="${stream.embed_url}" />`;
    }
  }

  closeFilter() {
    this.filterDialog = false;
    this.setToDefaultFilter();
    this.fetchData();
    if (this.$route.path !== '/') this.$router.push('/');
  }

  changedFilter(data) {
    this.filter = Object.assign(data);
  }

  chooseDevicesSelected(value = false) {
    this.devicesSelect = value;
  }

  selectAll(devices) {
    this.groupDevices = new Map();
    if (devices.length)
      devices.forEach((device) => this.groupDevices.set(device.id, device));
  }

  @Watch('$route', { immediate: true, deep: true })
  @Watch('editFilterMode')
  setFilterDisplay() {
    const isExistFilter = this.$route.path.includes('custom');
    const isCreateFilter = this.$route.path.includes('/filter/create');
    if ((this.editFilterMode && isExistFilter) || isCreateFilter) {
      this.filterDialog = true;
    } else {
      this.filterDialog = false;
      if (this.$route.path === '/'){
        this.setToDefaultFilter();
      }
    }
    if (isExistFilter || isCreateFilter) {
      this.devicesSelect = false;
      this.$refs.map['clearDeviceSelector']();
      this.clearDevice();
    }
  }

  @Watch('devices', { immediate: true, deep: true })
  updateDevicesWithMqttData(value){
    if (this.devicesChangedFromMqtt.size) {
      [...this.devicesChangedFromMqtt.values()].forEach((device) => {
        this.$refs.map['updateFeatureIcon'](device);
        this.removeDeviceChangedFromMqtt(device.id);
      });
    }
  }

  @Watch('filter')
  updateFilterDevices() {
    this.filteredData();
  }

  setToDefaultFilter() {
    this.filter = {
      groups: ['all'],
      classes: null,
      type: null,
      zoom: null,
      status: null,
      phase: null,
      circuit: null,
      name: 'default',
      map: {
        bounds: null,
        center: null,
        zoom: null
      },
      assetType: null
    };
  }

  beforeDestroy() {   
    this.clearDevice();
  }
}
